// ============================================================================
// DocumentEditionService
// --------------------
// DocumentEdition module related services
// ============================================================================

// -------
// Imports
// -------
import { Config } from '@/services/ConfigService'
import { store } from '@/store'

import DocumentEditionApi from '@/apis/DocumentEditionApi'

import OS from '@/helpers/os'
import { mimetypes as Mimetypes, getFileExtension } from '@/helpers/files'

// ---------
// Internals
// ---------
const Private = {
	libreOfficeSettings: null,
	isDocumentEditable: function (document) {
		let result
		if (!document || !document.name || document.name.indexOf('.') === -1 || document.deleted_at) {
			result = false
		} else {
			const documentExtension = getFileExtension(document.name)
			result =
				Private.libreOfficeSettings?.querySelector(
					`app[name="${document.type}"] action[ext="${documentExtension}"][name="edit"], app[name="${document.type}"] action[ext=""][name="edit"], app action[ext="${documentExtension}"][name="edit"]`
				) != null
		}
		return result
	},
	isDocumentOnlineEditable: function (document) {
		return Private.isDocumentEditable(document) && OS.isWindows && Private.isOfficeDocument(document)
	},
	getOfficeDocumentType: function () { },
	getDocumentEditionDesktopEditorURL: function (officeDocumentType, document) {
		return DocumentEditionApi.getWebDavURL(document.vendor_id, document.id)
			.then(response => response.data.data)
			.then(({ url: webDavURL }) => {
				const protocol = officeDocumentType.slice(0, 2) + '-' + officeDocumentType.slice(2)
				const uri = `${protocol}://ofe|u|${webDavURL}|s|${webDavURL}`

				return {
					local: true,
					url: uri
				}
			})
	},
	getDocumentEditionOnlineEditorURL: function (vendorId, document, version = null, readOnlyMode = false) {
		let result
		if (!Private.isDocumentEditable(document)) {
			result = Promise.resolve(false)
		} else {
			const fileUrl = new URL(
				`${Config.VUE_APP_ROOT_WOPI}/files/${vendorId}-${document.id}-${version ? version.id : document.latestVersion?.id
				}?access_token=${localStorage.getItem('token')}&read_only=${readOnlyMode}`
			)
			const url = new URL(`${Config.VUE_APP_WOPI_BASE_URL}/loleaflet/dist/loleaflet.html`)
			url.searchParams.append('file_path', fileUrl.href)
			result = Promise.resolve({ local: false, url: url.href })
		}
		return result
	},
	updateStoredPreferences: function (preferences) {
		return store.dispatch('documents/edition/setPreferences', preferences)
	},
	isGuest: function () {
		return store.state?.company?.userRoles?.isGuest ?? true
	},
	isExcel: function (document) {
		let result
		switch (document.type) {
			case Mimetypes.xls:
			case Mimetypes.xlsx:
			case Mimetypes.xlsm:
			case Mimetypes.xlsb:
			case Mimetypes.xla:
			case Mimetypes.xlc:
			case Mimetypes.xlm:
			case Mimetypes.xlt:
			case Mimetypes.csv:
			case Mimetypes.xlw:
				result = true
				break
			case Mimetypes.bin:
				result = getFileExtension(document.name).toLowerCase() == 'xlsm'
				break
			default:
				result = false
				break
		}
		return result
	},
	isPowerPoint: function (document) {
		let result
		switch (document.type) {
			case Mimetypes.ppt:
			case Mimetypes.pptx:
			case Mimetypes.pot:
			case Mimetypes.pps:
				result = true
				break
			default:
				result = false
				break
		}
		return result
	},
	isWord: function (document) {
		let result
		switch (document.type) {
			case Mimetypes.doc:
			case Mimetypes.docx:
			case Mimetypes.dot:
				result = true
				break
			default:
				result = false
				break
		}
		return result
	},
	isTxt: function (document) {
		return document.type == Mimetypes.txt
	},
	isOfficeDocument: function (document) {
		return Private.isExcel(document) || Private.isPowerPoint(document) || Private.isWord(document) || Private.isTxt(document)
	},
	loadPreferences: function () {
		return DocumentEditionApi.getPreferences()
			.promise.then(response => response.data.data)
			.then(preferences => {
				return Private.updateStoredPreferences(preferences).then(() => preferences)
			})
	}
}

// Initialize on app loading
DocumentEditionApi.libreOffice
	.listSettings()
	.promise.catch(e => {
		let err = new Error('Fail to fetch libre-office online discovery')
		err.stack += '\nCaused by: ' + e.stack
		throw err
	})
	.then(res => res.data)
	.then(document => {
		Private.libreOfficeSettings = document.documentElement
	})
DocumentEditionApi.getPreferences()
	.promise.then(response => response.data.data)
	.then(preferences => {
		return Private.updateStoredPreferences(preferences).then(() => preferences)
	})

export default {
	loadPreferences: Private.loadPreferences,
	updatePreferences: function (data) {
		return DocumentEditionApi.updatePreferences(data)
			.promise.then(res => res.data.data)
			.then(preferences => {
				if (store.state.documents.edition) {
					Private.updateStoredPreferences(preferences)
				}
				return preferences
			})
	},
	getDocumentEditionLaunchingOptions: function (vendorId, document, version = null, readOnlyMode = false) {
		let result
		if (!Private.isDocumentEditable(document)) {
			result = Promise.reject(false)
		} else if (Private.isDocumentOnlineEditable(document)) {
			let promise = Promise.resolve()
			if (!store.state.documents.edition?.preferences) {
				promise = Private.loadPreferences()
			}
			result = promise.then(() => {
				const preferences = store.state.documents.edition.preferences
				let officeDocumentType
				if (Private.isExcel(document)) {
					officeDocumentType = 'msexcel'
				} else if (Private.isPowerPoint(document)) {
					officeDocumentType = 'mspowerpoint'
				} else {
					officeDocumentType = 'msword'
				}
				let result
				if (preferences[officeDocumentType]) {
					result = Private.getDocumentEditionDesktopEditorURL(officeDocumentType, document)
				} else {
					result = Private.getDocumentEditionOnlineEditorURL(vendorId, document, version, readOnlyMode)
				}
				return result
			})
		} else {
			result = Private.getDocumentEditionOnlineEditorURL(vendorId, document, version, readOnlyMode)
		}
		return result
	},
	getDocumentEditionOnlineEditorURL: Private.getDocumentEditionOnlineEditorURL,
	isDocumentEditable: Private.isDocumentEditable,
	isDocumentOnlineEditable: Private.isDocumentOnlineEditable
}
